html{
  font-family: 'Poppins', sans-serif;
  background-color: #051721;
}
body, html, #root{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wrapper{
  width:100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.inner-box{
  max-width: 320px;
  text-align: center;
}

.rounded-overlay{
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
  margin: auto;
}
.rounded-overlay img{
  width: 200px;
  height: 200px;
}

h1{
  color:#DEE2E3;
  font-size: 1.9rem;
  font-weight: 900;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  margin-bottom:1.5rem;
  margin-top:1.5rem;
}

h2{
  color:#276968;
  font-weight:300;
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  margin:0;
}

h2 b{
  font-weight: 700;
}

.social{
  display:flex;
  text-align: center;
  justify-content: center;
  margin-top:6rem;
}
.social-item{
  margin:10px;
}
.social-item a:hover{
  opacity: 0.6;
}